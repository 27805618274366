var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LeftModal',{attrs:{"toolbar-name":"کیف‌ پول وندار"},on:{"closeModal":function($event){return _vm.$emit('close-modal')}}},[_c('div',{staticClass:"box-style"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center"},[(_vm.walletData?.icon)?_c('v-img',{staticClass:"ml-2",attrs:{"width":"30","max-height":"30","max-width":"30","height":"30","src":_vm.walletData.icon}}):_vm._e(),_c('span',{staticClass:"font_22"},[_vm._v("وندار ")])],1)],1),_c('WalletDetailRow',{attrs:{"text":"موجودی کل","value":_vm.balanceAmountWithThousandSeparatorForRialAmount(_vm.walletData.balance)}}),_c('WalletDetailRow',{attrs:{"text":"قابل برداشت","value":_vm.balanceAmountWithThousandSeparatorForTomanAmount(
          _vm.walletAmountDetails.walletDeductibleAmount
        )}}),_c('WalletDetailRow',{attrs:{"text":"مبالغ تجمیعی شاپرک","value":_vm.balanceAmountWithThousandSeparatorForTomanAmount(
          _vm.walletAmountDetails.paymentFacilitatorNonDeductibleAmount
        )}}),_c('WalletDetailRow',{attrs:{"text":"کیف پول کارمزدی (غیرقابل برداشت)","value":_vm.balanceAmountWithThousandSeparatorForTomanAmount(
          _vm.walletAmountDetails.feeNonDeductibleAmount
        )}}),_c('WalletDetailRow',{attrs:{"text":"مبالغ تجمیع شده پرداخت خودکار (غیرقابل برداشت)","value":_vm.balanceAmountWithThousandSeparatorForTomanAmount(
          _vm.walletAmountDetails.directDebitNonDeductibleAmount
        )}})],1),(_vm.isA2aActive && !_vm.noActiveBank)?_c('A2aDailyCard',{staticClass:"mt-4",class:{
      'pb-6': _vm.reuseIsMobile,
      'pb-14': _vm.$vuetify.breakpoint.smAndUp,
    },attrs:{"is-there-bank-accounts":true},on:{"noBankIsActive":function($event){_vm.noActiveBank = true}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }