const apiHeader = (context) => ({
  "Content-Type": "application/json",
  authorization: `Bearer ${context.$store.state.token}`,
  Accept: "application/json",
})

export default (context) => ({
  httpGetAccountantInvoice(data) {
    return context.$http.get(
      `${context.$store.getters.apiUrlParamV3}/accountant/report/invoice?year=${data.year}&month=${data.month}`,
      {
        headers: apiHeader(context),
      }
    )
  },
})
