<script>
import screenSize from "@/mixins/screenSize"

export default {
  name: "DetailWalletElement",
  mixins: [screenSize],
  props: {
    text: {
      type: String,
      required: true,
    },
    toolTipText: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <v-flex>
    <slot name="paragraph"></slot>
    <v-layout
      class="bold"
      :align-center="!reuseIsMobile"
      wrap
      :class="{ 'd-flex flex-column align-start': reuseIsMobile }"
    >
      <v-flex>
        {{ text }}
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              size="20"
              style="direction: rtl; width: 20px"
              class="van_color05--text"
              v-on="on"
            >
              mdi_info_outline
            </v-icon>
          </template>
          <span class="font_33">
            {{ toolTipText }}
          </span>
        </v-tooltip>
      </v-flex>
      <v-flex>
        <v-flex
          style="text-align-last: left !important"
          :class="{ 'mr-4': !reuseIsMobile, 'mt-1': reuseIsMobile }"
        >
          <span dir="ltr" class="ml-1">
            <slot />
          </span>

          {{ currencyName }}
        </v-flex>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<style scoped lang="scss"></style>
