<template>
  <div>
    <label>
      <input type="text" :class="className" placeholder="انتخاب" />
    </label>
    <DatePicker
      v-model="selectedDate"
      :type="hasHour ? 'datetime' : 'date'"
      compact-time
      :format="dateFormat"
      :display-format="displayDateFormat"
      color="#4C9F87"
      :custom-input="`.${className}`"
    />
  </div>
</template>

<script>
import DatePicker from "vue-persian-datetime-picker"
const jalaaliMoment = require("moment-jalaali")
jalaaliMoment.loadPersian({ dialect: "persian-modern" })

export default {
  name: "CustomDatePicker",
  components: {
    DatePicker,
  },
  inject: ["selectedFromDate", "selectedToDate", "shouldReset"],
  props: {
    className: {
      type: String,
      default: "custom-input",
    },
    isToDate: {
      type: Boolean,
      default: false,
    },
    hasHour: {
      type: Boolean,
      default: true,
    },
    selectedDateByUser: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedDate: "",
    }
  },
  computed: {
    displayDateFormat() {
      return this.hasHour ? "HH:mm - jYYYY/jMM/jDD" : "jYYYY/jMM/jDD"
    },
    dateFormat() {
      return this.hasHour ? "jYYYY/jMM/jDD HH:mm" : "jYYYY/jMM/jDD"
    },
  },
  watch: {
    selectedDate(dateValue) {
      this.$emit("date-value", dateValue)
    },
  },
  mounted() {
    this.setSelectedDate()
    this.resetDate()
  },

  methods: {
    setSelectedDate() {
      if (this.isToDate) {
        if (this.selectedToDate) {
          this.selectedDate = this.selectedToDate
        } else {
          this.setCurrentDate()
        }
      } else {
        if (this.selectedFromDate) {
          this.selectedDate = this.selectedFromDate
        }
      }
    },
    setCurrentDate() {
      if (this.isToDate) {
        if (this.hasHour) {
          this.selectedDate = jalaaliMoment(new Date()).format(
            "jYYYY/jMM/jDD HH:mm"
          )
        } else {
          this.selectedDate = jalaaliMoment(new Date()).format("jYYYY/jMM/jDD")
        }
      }
    },
    resetDate() {
      if (this.shouldReset) {
        if (this.isToDate) {
          this.setCurrentDate()
        } else {
          this.selectedDate = ""
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-input,
.custom-input-second {
  border: 1px solid #999;
  height: 36px;
  text-align: left;
  border-radius: 4px;
  padding-left: 10px;
  width: 140px;
  cursor: pointer;
  color: $van_color03;
}

label {
  position: relative;
}

label:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 18px;
  background: url("@/assets/img/Calendar.svg") center / contain no-repeat;
  cursor: pointer;
}

input:focus {
  outline: none;
}
</style>
