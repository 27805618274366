<template>
  <v-card class="singleBox addsettlement">
    <toolbar
      status-channel="addRefundModal"
      :add-refund-modal="true"
      :disable-btn="disableBtn"
      :loading="loading"
      @closeClick="closeSecondmodal"
      @toolbarBtnClick="approveRefund = true"
    />

    <v-layout>
      <v-flex class="mb-0 py-5 px-10">
        <v-layout class="align-center justify-start">
          <v-flex style="max-width: 40px">
            <v-img
              max-width="31"
              :src="require('@/assets/img/newIcon/pending-square-yellow.svg')"
            />
          </v-flex>
          <v-flex class="font-size-12 text--van_color02">
            مبلغ این تراکنش از موجودی قابل برداشت کیف پول کسر می‌شود.</v-flex
          >
        </v-layout>
      </v-flex>
    </v-layout>
    <!--    transaction detail-->
    <v-layout>
      <MinDetailsBoxWithIcon
        class="ml-1 mb-0"
        :title="totalAmount"
        subtitle="مبلغ کل"
      />
      <MinDetailsBoxWithIcon
        class="mr-1 mb-0"
        :title="maskCard"
        title-dir="ltr"
        icon="bank-card.svg"
        icon-box-color="#d44d424d"
        subtitle="شماره کارت"
      />
    </v-layout>

    <v-layout>
      <v-flex class="mt-5">
        <v-form ref="form" v-model="valid">
          <!--          radio btn for select refund type-->
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label">
              بازگشت وجه
              <span>*</span>
            </v-flex>
            <v-flex class="withlabel big">
              <v-radio-group
                v-model="refundType"
                class="simpleRadio select-refund-type-radio my-0 justify-sm-space-between"
                style="margin: 0 !important"
                row
                :rules="[(v) => !!v]"
              >
                <v-radio
                  class="mr-0 text-right"
                  label="بازگشت کل مبلغ"
                  value="complete"
                  color="van_green"
                />
                <v-radio
                  class="mr-0 text-right"
                  label="ورود مبلغ دلخواه"
                  value="partial"
                  color="van_green"
                />
              </v-radio-group>
            </v-flex>
          </v-layout>

          <!-- price amount -->
          <v-layout
            v-if="refundType"
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label"> مبلغ </v-flex>
            <v-flex
              v-if="refundType === 'complete'"
              class="gray-text font_2 px-5 py-4 mb-0"
            >
              مبلغ "
              {{ mablagh }} {{ currencyName }}
              " به حساب کاربر بازگشت داده می‌شود
            </v-flex>
            <v-flex v-if="refundType === 'partial'" class="withlabel big">
              <v-text-field
                ref="amount"
                v-model="thousandSeprator"
                solo
                flat
                height="40"
                outlined
                :suffix="currencyName"
                type="tel"
                :rules="[
                  (v) => !!v || '<span>مبلغ الزامی است</span>',
                  (v) =>
                    (!!v && minAmountCheck) ||
                    '<span>حداقل مبلغ ورودی 1000 تومان است</span>',
                  (v) =>
                    (!!v && checkAmount) ||
                    '<span>مبلغ وارد شده بیشتر از مبلغ کل است، لطفا اصلاح کنید</span>',
                ]"
                @keydown="numberKeyHandler"
              >
                <template #message="message">
                  <div v-html="message.message" />
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <!-- time prediction and wage -->
          <div
            v-if="
              refundType === 'complete' ||
              (refundType === 'partial' && amount && minAmountCheck)
            "
          >
            <!-- inputPrediction -->
            <div class="font_18 iconAndMablagh">
              <img
                width="16"
                height="16"
                :src="require('@/assets/img/clock-outline.svg')"
              />
              <span v-if="inputPrediction != null">
                واریز
                {{ inputPrediction }}
              </span>
              <span
                v-else
                class="default_gray"
                style="width: 25%; margin: 4px 10px 0 0"
              />
            </div>
            <!-- wage -->
            <div class="font_18 iconAndMablagh mb-2">
              <img
                width="16"
                height="16"
                :src="require('@/assets/img/wage.svg')"
              />
              <span v-if="wage != null">
                کارمزد
                {{ wage | currency }}
                {{ currencyName }}
              </span>
              <span
                v-else
                class="default_gray"
                style="width: 25%; margin: 4px 10px 0 0"
              />
            </div>
          </div>

          <!-- comment -->
          <v-layout
            :column="$vuetify.breakpoint.xsOnly"
            justify-start
            align-start
          >
            <v-flex class="label"> یادداشت </v-flex>
            <v-flex class="withlabel big">
              <v-textarea
                v-model="comment"
                solo
                flat
                rows="3"
                outlined
                placeholder="متنی اختیاری که فقط برای شما قابل مشاهده است"
                :disabled="loading"
              >
                <template #message="message">
                  <div v-html="message.message" />
                </template>
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>

    <general-dialog
      :active="approveRefund"
      content-class="confirmationMessageDialog"
      custom-style="height:490px"
      style="height: 100%"
      logo="Warning.svg"
      title="از بازگشت وجه مطمئنید؟"
      description="امکان لغو «بازگشت وجه» وجود ندارد"
      btn1-text="بله، انجام شود"
      :btn1-loading="loading"
      btn2-text="منصرف شدم"
      btn2-class="btn_small_normal"
      @btn1Clicked="sendForm()"
      @btn2Clicked="close_modal(false)"
    >
      <template #extra>
        <div
          class="mt-6 font_2 pa-5 text-center"
          style="background-color: #f9d35833; width: 100%"
        >
          کارمزد این تراکنش
          <span class="bold">{{ wage | currency }} {{ currencyName }}</span> است
        </div>
        <div
          class="font_2 mt-2 pa-5"
          style="align-items: center; width: 100%; background-color: #f5f5f5"
        >
          <v-layout align-center>
            <v-flex class="font_8"> مبلغ </v-flex>
            <v-flex style="text-align: left">
              {{ refundType === "partial" ? thousandSeprator : mablagh }}
              {{ currencyName }}
            </v-flex>
          </v-layout>
          <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
          <v-layout align-center>
            <v-flex class="font_8"> کارت مقصد </v-flex>
            <v-flex style="text-align: left; direction: ltr">
              {{ maskCard }}
            </v-flex>
          </v-layout>
          <hr class="my-2" style="border: 1px dashed #bbbbbb; width: 100%" />
          <!-- <v-layout row align-center>
            <v-flex class="font_8">کارمزد</v-flex>
            <v-flex style="text-align: left">{{ wage | currency }} {{currencyName}}</v-flex>
          </v-layout> -->
          <!-- <hr class="my-2" style="border: 1px dashed #BBBBBB;width: 100%"> -->
          <v-layout align-center>
            <v-flex class="font_8"> زمان تخمینی واریز </v-flex>
            <v-flex style="text-align: left">
              {{ inputPrediction }}
            </v-flex>
          </v-layout>
        </div>
      </template>
    </general-dialog>
  </v-card>
</template>

<script>
import GeneralDialog from "@/components/modals/generalDialog"
import toolbar from "@/components/report/elements/show/toolbar/toolbar.vue"
import MinDetailsBoxWithIcon from "../../elements/minDetailsBoxWithIcon"
import servicesRepository from "@/services/servicesRepository"

function formatAsCurrency(value, dec) {
  dec = dec || 0
  if (value === null) {
    return null
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}
export default {
  name: "AddRefund",
  components: {
    MinDetailsBoxWithIcon,
    GeneralDialog,
    toolbar,
  },
  props: ["item", "secoundModal"],
  data: function () {
    return {
      loading: false,
      valid: false,
      refundType: null,
      mablagh: null, //v-model of mablagh
      maskCard: "",
      comment: "",
      amount: null,
      all: 0,
      today: 0,
      wage: null,
      inputPrediction: null,
      approveRefund: false,
      wageGeted: false,
      timePredictionGeted: false,
      timeOut: null,
      walletAmountValidation: false,
    }
  },
  computed: {
    thousandSeprator: {
      get: function () {
        return formatAsCurrency(this.amount, 0)
      },
      set: function (newValue) {
        if (newValue) {
          this.amount = Number(
            this.convertNumbers2English(newValue).replace(/[^0-9.]/g, "")
          )
        } else {
          this.amount = null
        }
      },
    },
    disableBtn() {
      return !(
        this.wageGeted &&
        this.timePredictionGeted &&
        this.valid &&
        this.walletAmountValidation
      )
    },
    totalAmount() {
      return this.mablagh + " " + this.currencyName
    },
    amountToRialAmount() {
      if (this.$store.state.user.currency === "RIAL") return this.amount
      return parseInt(this.amount) * 10
    },
    checkAmount() {
      if (this.$store.state.user.currency === "RIAL")
        return this.amount <= parseInt(this.item.amount) * 10
      return this.amount <= parseInt(this.item.amount)
    },
    minAmountCheck() {
      if (this.$store.state.user.currency === "RIAL") {
        return this.amount >= 10000
      }
      return this.amount >= 1000
    },
  },
  watch: {
    refundType: function (newValue) {
      if (newValue === "complete") {
        this.getWage()
        this.getPrediction()
      } else {
        this.wageGeted = false
        this.timePredictionGeted = false
        this.inputPrediction = null
        this.wage = null
        this.amount = null
      }
    },
    amount(newValue) {
      if (newValue && this.minAmountCheck) {
        clearTimeout(this.timeOut)
        this.timePredictionGeted = false
        this.wageGeted = false
        this.wage = null
        this.inputPrediction = null
        this.timeOut = setTimeout(() => {
          this.getWage()
          this.getPrediction()
        }, 1000)
      } else {
        this.timeOut = null
      }
    },
  },
  mounted() {
    this.mablagh = this.$options.filters.currency(this.item.amount)
    this.maskCard = this.item.card_number
  },
  methods: {
    close_modal(status) {
      this.approveRefund = false
      this.loading = false
      this.$emit("closeModal", { status: status })
    },
    closeSecondmodal() {
      this.secoundModal
        ? this.$emit("closeSecoundModal")
        : this.$emit("closeModal", { status: false })
    },
    getPrediction() {
      this.timePredictionGeted = false
      this.inputPrediction = null
      let url =
        this.$store.getters.apiUrlParam +
        "/transaction/" +
        this.item.id +
        "/refund/prediction"
      if (this.refundType === "partial" && this.checkAmount) {
        url += `?amount=${this.amountToRialAmount}`
      }
      this.$http
        .get(url)
        .then((response) => {
          let result = response.data.result.settlement_prediction
          let text
          if (result.extra != null && result.extra != "") {
            text = result.extra + " ساعت " + result.time.slice(0, 5)
          } else {
            text = result.date + " ساعت " + result.time.slice(0, 5)
          }
          this.inputPrediction = text
          this.timePredictionGeted = true
        })
        .catch((error) => {})
    },
    getWage() {
      this.wageGeted = false
      this.wage = null
      let url =
        this.$store.getters.apiUrlParam +
        "/transaction/" +
        this.item.id +
        "/refund/wage"
      if (this.refundType === "partial" && this.checkAmount) {
        url += `?amount=${this.amountToRialAmount}`
      }
      this.$http
        .get(url)
        .then((response) => {
          this.wage = response.data.wage
          this.wageGeted = true
          if (this.refundType === "complete") {
            this.checkWalletAmountValidation(this.item.amount)
          } else if (this.refundType === "partial" && this.checkAmount) {
            this.checkWalletAmountValidation(this.amount)
          }
        })
        .catch((error) => {})
    },
    sendForm() {
      this.loading = true
      let url = ""
      url = this.$store.state.isUser
        ? "/v3/personal/transaction/" + this.item.id + "/refund"
        : "/v3/business/" +
          this.$store.state.business.business_name +
          "/transaction/" +
          this.item.id +
          "/refund"

      let data = {
        comment: this.comment,
      }

      if (this.refundType === "partial" && this.checkAmount) {
        data.amount = this.amountToRialAmount
      }
      this.$http
        .post(this.$store.state.api_base_url_withoutV + url, data)
        .then((response) => {
          this.close_modal(true)
          this.$store.commit(
            "callSuccessSnackbar",
            "عملیات بازگشت وجه با موفقیت انجام شد"
          )
        })
        .catch((error) => {
          this.close_modal(false)
        })
    },
    checkWalletAmountValidation(quantity) {
      const walletDeductibleAmount =
        this.$store.state.walletAmountDetails.walletDeductibleAmount
      if (
        walletDeductibleAmount === null ||
        walletDeductibleAmount === undefined
      ) {
        servicesRepository(this)
          .settlementServices.httpGetSettlementInfo()
          .then((response) => {
            let responseData = response.data.data

            this.$store.commit("setWalletAmountDetails", responseData)

            this.doAmountValidation(
              quantity,
              responseData.wallet_deductible_amount
            )
          })
      } else {
        this.doAmountValidation(quantity, walletDeductibleAmount)
      }
    },
    doAmountValidation(quantity, walletDeductibleAmount) {
      if (walletDeductibleAmount < quantity + this.wage) {
        this.walletAmountValidation = false
        this.$store.commit("callsnackbar", "موجودی کیف پول کافی نیست")
      } else {
        this.walletAmountValidation = true
      }
    },
  },
}
</script>
<style scoped>
.gray-text {
  background-color: #f5f5f5;
  height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  max-width: 350px;
}
</style>
