<template>
  <v-form v-model="formValidation">
    <div
      class="d-flex mb-3"
      :class="{ 'flex-column': isMobileMode || isInModal }"
    >
      <div class="d-flex w-100" :class="{ 'flex-column': isMobileMode }">
        <v-flex class="label font-11-no-999-18">
          حوزه فعالیت <span>*</span>
        </v-flex>

        <v-flex class="withlabel big">
          <v-autocomplete
            ref="mccCode"
            v-model="category_id"
            :rules="category_id_Rules"
            :items="$store.state.newbusiness.businessCategoriesList"
            item-text="value"
            item-value="key"
            solo
            flat
            height="40"
            outlined
            single-line
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            no-data-text="اطلاعاتی برای نمایش موجود نیست"
            placeholder="حوزه مورد نظر را جست‌وجو کنید"
            @focus="focusCategoryField"
            @blur="blurCategoryField"
          />
        </v-flex>
      </div>
      <transition-group
        v-if="isMobileMode || isInModal"
        :class="{ 'alignment-style': !isMobileMode && isInModal }"
        name="list"
      >
        <div
          v-for="(item, index) in mccCodeGuidanceItemsStep4"
          v-show="$refs.mccCode && $refs.mccCode.isFocused"
          :key="index"
          class="align-center max-width-250"
        >
          <v-icon class="font-11-no-bbb-18" style="font-size: 12px">
            info_outline
          </v-icon>
          <span class="font-11-no-bbb-18"> {{ item }} </span>
          <br v-if="index !== mccCodeGuidanceItemsStep4.length - 1" />
        </div>
      </transition-group>
    </div>

    <InputContainer label="آدرس وب‌سایت">
      <div :class="{ 'w-100': isMobileMode }">
        <v-flex class="withlabel big">
          <v-text-field
            ref="websiteUrl"
            v-model="websiteUrlComputed"
            solo
            flat
            height="40"
            outlined
            :rules="webSiteUrlRule"
            @focus="focusWebsiteUrl"
            @blur="blurWebsiteUrl"
          >
            <template #message="message">
              <div v-html="message.message"></div>
            </template>
          </v-text-field>
        </v-flex>
        <transition-group v-if="isMobileMode || isInModal" name="list">
          <div
            v-for="(item, index) in websiteUrlGuidanceItems"
            v-show="$refs.websiteUrl && $refs.websiteUrl.isFocused"
            :key="index"
            class="align-center max-width-250"
          >
            <v-icon class="font-11-no-bbb-18 ml-1" style="font-size: 12px">
              info_outline
            </v-icon>
            <span class="font-11-no-bbb-18" style="white-space: pre-wrap">
              {{ item }}
            </span>
            <br v-if="index !== websiteUrlGuidanceItems.length - 1" />
          </div>
        </transition-group>
      </div>
    </InputContainer>
  </v-form>
</template>

<script>
import InputContainer from "@/components/elements/inputContainer.vue"
import { mapMutations } from "vuex"

export default {
  name: "CreatingIpgFirstPart",
  components: {
    InputContainer,
  },
  inject: ["mccCodeGuidanceItemsStep4", "websiteUrlGuidanceItems"],
  props: {
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      category_id_Rules: [(v) => !!v || ""],
      websiteUrl: null,
      webSiteUrlRule: [
        (v) => !!v || "<span>آدرس وب‌سایت الزامی است</span>",
        (v) =>
          (!!v && this.checkFormat([v], this.websiteUrlPattern)) ||
          "<span> فرمت صحیح آدرس وب سایت را وارد کنید</span>",
      ],
      websiteUrlPattern: new RegExp(
        "^[a-z\\d]([a-z\\d-]*[a-z\\d])?\\.[a-z]{2,}$",
        "i"
      ),
      formValidation: false,
    }
  },
  computed: {
    websiteUrlComputed: {
      get() {
        return this.websiteUrl
      },
      set(val) {
        this.websiteUrl = val.trim().toLowerCase()
      },
    },
    category_id: {
      get() {
        return this.$store.state.business.category_id
      },
      set(new_category_id) {
        this.$store.commit("updateCategoryId", new_category_id)
      },
    },
    providePayload() {
      return {
        websiteUrl: this.websiteUrlComputed,
        isValid: this.formValidation,
      }
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  watch: {
    providePayload(val) {
      this.$emit("first-form-validation", val)
    },
  },
  methods: {
    ...mapMutations("newbusiness", [
      "changeCategoryFieldFocusState",
      "changeWebsiteUrlFieldFocusState",
      "changeWageFieldShowingState",
    ]),
    focusCategoryField() {
      this.changeCategoryFieldFocusState(true)
    },
    blurCategoryField() {
      this.changeCategoryFieldFocusState(false)
    },
    checkFormat(items, selectedPattern) {
      let doesAllItemsMatch = true

      for (let element of items) {
        if (element.includes("localhost")) continue
        //convert farsi digits to english
        element = this.convertNumbers2English(element)
        if (selectedPattern && !selectedPattern.test(element)) {
          // if item does not match the pattern return false
          doesAllItemsMatch = false
          return false
        }
      }
      return doesAllItemsMatch
    },
    focusWebsiteUrl() {
      this.changeWebsiteUrlFieldFocusState(true)
      this.changeWageFieldShowingState(false)
    },
    blurWebsiteUrl() {
      this.changeWebsiteUrlFieldFocusState(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.list-enter,
.list-leave-to {
  visibility: hidden;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}

.alignment-style {
  margin-right: 110px;
}
</style>
