<script>
import screenSize from "@/mixins/screenSize"

export default {
  name: "WalletDetailRow",
  mixins: [screenSize],
  props: {
    text: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
}
</script>

<template>
  <v-row>
    <v-col
      class="d-flex background-color radius-4 mt-2"
      :class="{
        'flex-column align-start': reuseIsMobile,
        'align-center justify-space-between': !reuseIsMobile,
      }"
    >
      <span class="font_30"> {{ text }} </span>
      <div>
        <span class="font_22"> {{ value }} </span>
        <span class="font-11-no-000-18">
          {{ currencyName }}
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.background-color {
  background: #f5f5f5;
}
.radius-4 {
  border-radius: 4px;
}
</style>
